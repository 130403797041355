export const GET_SIA_ANNOS = 'GET_SIA_ANNOS'
export const GET_SIA_LABELS = 'GET_SIA_LABELS'
export const SIA_SELECT_ANNO = 'SIA_SELECT_ANNO'
export const SIA_KEY_DOWN = 'SIA_KEY_DOWN'
export const SIA_KEY_UP = 'SIA_KEY_UP'
export const SIA_SET_UICONFIG = 'SIA_SET_UICONFIG'
export const SIA_SHOW_SINGLE_ANNO = 'SIA_SHOW_SINGLE_ANNO'
export const SIA_SELECT_TOOL = 'SIA_SELECT_TOOL'
export const SIA_SHOW_LABEL_INPUT = 'SIA_SHOW_LABEL_INPUT'
export const SIA_GET_NEXT_IMAGE = 'SIA_GET_NEXT_IMAGE'
export const SIA_GET_PREV_IMAGE = 'SIA_GET_PREV_IMAGE'
export const SIA_FULLSCREEN = 'SIA_FULLSCREEN'
export const SIA_IMAGE_LOADED = 'SIA_IMAGE_LOADED'
export const SIA_UPDATE_REDUX_ANNOS = 'SIA_UPDATE_REDUX_ANNOS'
export const SIA_REQUEST_ANNO_UPDATE = 'SIA_REQUEST_ANNO_UPDATE'
export const SIA_APPLIED_FULLSCREEN = 'SIA_APPLIED_FULLSCREEN'
export const SIA_LAYOUT_UPDATE = 'SIA_LAYOUT_UPDATE'
export const SIA_IMGLABELINPUT_SHOW = 'SIA_IMGLABELINPUT_SHOW'
export const SIA_SET_SVG = 'SIA_SET_SVG'
export const GET_SIA_CONFIG = 'GET_SIA_CONFIG'
export const SIA_TASK_FINISHED = 'SIA_TASK_FINISHED'
export const SIA_IMG_JUNK = 'SIA_IMG_JUNK'
export const SIA_APPLY_FILTER = 'SIA_APPLY_FILTER'
