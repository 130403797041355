// Running
export const PIPELINE_RUNNING_GET_PIPELINES = 'PIPELINE_RUNNING_GET_PIPELINES'
export const PIPELINE_RUNNING_GET_PIPELINES_STATUS = 'PIPELINE_RUNNING_GET_PIPELINES_STATUS'
export const PIPELINE_RUNNING_GET_PIPELINE = 'PIPELINE_RUNNING_GET_PIPELINE'
export const PIPELINE_RUNNING_DELETE = 'PIPELINE_RUNNING_DELETE'
export const PIPELINE_RUNNING_PAUSE = 'PIPELINE_RUNNING_PAUSE'
export const PIPELINE_RUNNING_PLAY = 'PIPELINE_RUNNING_PLAY'
export const PIPELINE_RUNNNING_RESET = 'PIPELINE_RUNNNING_RESET'
export const PIPELINE_RUNNING_TOGGLE_MODAL = 'PIPELINE_RUNNING_TOGGLE_MODAL'
export const PIPELINE_RUNNING_VERIFY_TAB = 'PIPELINE_RUNNING_VERIFY_TAB'
export const PIPELINE_RUNNING_SELECT_TAB = 'PIPELINE_RUNNING_SELECT_TAB'
export const PIPELINE_RUNNING_UPDATE_ARGUMENTS_REQUEST_STATUS = 'PIPELINE_RUNNING_UPDATE_ARGUMENTS_REQUEST_STATUS' 

export const PIPELINE_START_GET_TEMPLATES = 'PIPELINE_START_GET_TEMPLATES'
export const PIPELINE_START_GET_TEMPLATES_STATUS = 'PIPELINE_START_GET_TEMPLATES_STATUS'