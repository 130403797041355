export const GET_USERS = 'GET_USERS'
export const SET_OWN_USER = 'SET_OWN_USER'
// export const CREATE_USER_FAILED = 'CREATE_USER_FAILED'
// export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
// export const CLEAN_CREATE_USER_MESSAGE = 'CLEAN_CREATE_USER_MESSAGE'
// export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
// export const DELETE_USER_FAILED = 'DELETE_USER_FAILED'
// export const CLEAN_DELETE_USER_MESSAGE = 'CLEAN_DELETE_USER_MESSAGE'
// export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
// export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED'
// export const CLEAN_UPDATE_USER_MESSAGE = 'CLEAN_UPDATE_USER_MESSAGE'
export const UPDATE_OWN_USER_SUCCESS = 'UPDATE_OWN_USER_SUCCESS'
export const UPDATE_OWN_USER_FAILED = 'UPDATE_OWN_USER_FAILED'
// export const CLEAN_UPDATE_OWN_USER_MESSAGE = 'CLEAN_UPDATE_OWN_USER_MESSAGE'

export const UPDATE_USER_STATUS = 'UPDATE_USER_STATUS'
export const DELETE_USER_STATUS = 'DELETE_USER_STATUS'
export const UPDATE_OWN_USER_STATUS = 'UPDATE_OWN_USER_STATUS'

