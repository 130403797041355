export const GET_GROUPS = 'GET_GROUPS'
export const CREATE_GROUP = 'CREATE_GROUP'
export const CREATE_GROUP_STATUS = 'CREATE_GROUP_STATUS'
// export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS'
// export const CREATE_GROUP_FAILED = 'CREATE_GROUP_FAILED'
// export const CLEAN_GROUP_CREATE_MESSAGE = 'CLEAN_GROUP_CREATE_MESSAGE'
export const DELETE_GROUP = 'DELETE_GROUP'
export const DELETE_GROUP_STATUS = 'DELETE_GROUP_STATUS'
// export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS'
// export const DELETE_GROUP_FAILED = 'DELETE_GROUP_FAILED'
// export const CLEAN_GROUP_DELETE_MESSAGE = 'CLEAN_GROUP_DELETE_MESSAGE'
